<template>
  <section-item title="服务案例" subtitle="Company Profile" height="807px" bgColor="#ffffff">
    <div class="home-item-box">
      <case-item @click="$navTo('/case-detail/product-anti-fraud')" :img="require('@/assets/case/case5.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-taoyuan')" :img="require('@/assets/case/case4.png')"></case-item>
      <case-item @click="$navTo('/case-detail/product-drowning')" :img="require('@/assets/case/case6.png')"></case-item>
    </div>
  </section-item>
</template>

<script>
import CaseItem from '../../components/case-item/case-item'

export default {
  name: 'home-case',
  components:{
    CaseItem
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.home-item-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>