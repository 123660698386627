<template>
  <div class="service-item-box">
    <div class="service-item">
      <img class="pic" :src="img"/>
      <span class="name">{{name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeServiceItem",
  props: {
    img: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.service-item-box{
  width: 20%;
}
.service-item {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pic{
  width: 100px;
  height: 100px;
}
.name{
  padding-top: 42px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
</style>

