<template>
 <section-item title="我们的服务" subtitle="Development Services" height="500px" bgColor="#ffffff" color="#FFFFFF" :style="{backgroundImage: 'url(' + require('../../assets/home-service-bg.png') + ')' }">
   <div class="home-item-box">
     <home-service-item :img="require('@/assets/home-service1.png')" name="软件定制"></home-service-item>
     <home-service-item :img="require('@/assets/home-service2.png')" name="小程序开发"></home-service-item>
     <home-service-item :img="require('@/assets/home-service3.png')" name="APP开发"></home-service-item>
     <home-service-item :img="require('@/assets/home-service4.png')" name="数据大屏开发"></home-service-item>
     <home-service-item :img="require('@/assets/home-service5.png')" name="电商平台系统开发"></home-service-item>
   </div>
 </section-item>
</template>

<script>
import HomeServiceItem from './home-service-item'

export default {
  name: 'home-service',
  components: {
    HomeServiceItem
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.home-item-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>