<template>
  <div style="background-color: #ffffff">
    <el-carousel height="600px" class="image-box">
      <el-carousel-item v-for="(item,index) in imgs" :key="index" style="text-align: center">
        <el-image class="image" :src="item" style="height: 100%"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  name: 'home-banner',
  // props: {
  //     imgs: {
  //         type: Array,
  //         default: ()=>[]
  //     },
  // },
  data() {
    return {
      imgs: [
        require('@/assets/banner/home-banner1.png'),
        require('@/assets/banner/home-banner3.png'),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1280px) {
  .image{
    width: 1280px;
    //height: auto;
  }
  .image-box{
    width: 1280px;
  }
}
</style>