<template>
  <div class="case-item-box" @click="$emit('click')" @mouseenter="showHover=true" @mouseleave="showHover=false">
    <img :src="img" class="pic"/>
    <div v-if="showHover" class="hover_container">
    </div>
  </div>
</template>

<script>
export default {
  name: "case-item",
  props: {
    img: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showHover: false
    };
  },
  methods: {},
  created() {
  },
};
</script>

<style lang="scss" scoped>
.case-item-box {
  background-color: #fbfbfd;
  width: 400px;
  height: 380px;
  position: relative;
  cursor: pointer;
}

.pic {
  width: 400px;
  height: 380px;
}

.hover_container {
  width: 400px;
  height: 380px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  text-align: center;
  line-height: 380px;
}
</style>

