<template>
  <div>
    <home-banner></home-banner>
    <home-case></home-case>
    <home-service></home-service>
  </div>
</template>

<script>
import HomeBanner from './home-banner'
import HomeCase from './home-case'
import HomeService from './home-service'

export default {
  components: {
    HomeBanner,
    HomeCase,
    HomeService
  },
  data() {
    return {}
  }
}
</script>

